import { css, keyframes } from '@emotion/react';

export const title = css`
    color: #ffb300;
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 4rem;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -0.15em;
        left: 0.4em;
        width: 1.2em;
        height: 0.3em;
        background-image: radial-gradient(circle, #ffb300 35%, transparent 0);
        background-size: 0.3em 0.3em;
        background-repeat: repeat-x;
        background-position: 0 0;
    }
`;

export const sectionTitle = css`
    margin-bottom: 1rem;

    &:not(:first-of-type) {
        margin-top: 2rem;
    }
`;

export const fields = css`
    max-width: 40rem;
    margin: auto;

    @media (min-width: 50rem) {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;

        & > div {
            flex: 1 1 40%;

            &:first-of-type {
                order: 2;
            }

            &:last-of-type {
                margin-right: 2rem;
                order: 1;
            }
        }
    }
`;

export const labelGroup = css`
    display: flex;

    & > label {
        flex: 1 1 30%;

        &:not(:last-of-type) {
            margin-right: 0.5rem;
        }
    }
`;

export const label = css`
    display: block;
    margin-bottom: 0.5rem;
    position: relative;

    input,
    textarea {
        display: block;
        width: 100%;
        font-family: Rubik, Helvetica, Arial, sans-serif;
        border-radius: 0.3rem;
        border: 2px solid #ffb300;
        padding: 0.5rem 1rem;
        transition: border 0.3s;

        &:focus {
            outline: none;
            border-color: #388e3c;
        }
    }

    textarea {
        min-height: 6rem;
        border-color: #999;
        margin-bottom: 2rem;
    }
`;

export const checkboxLabel = css`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    & > div:first-of-type {
        flex: 0 0 1.5rem;
        margin-right: 1rem;
    }
`;

export const requiredChecks = css`
    margin: 2rem 0;
`;

export const cardIcon = css`
    position: absolute;
    width: 2rem;
    top: 0.25rem;
    right: 0.5rem;
    color: #2e7d32;
`;

export const errorMessage = css`
    color: #555;
    margin-top: 0.5rem;
    padding: 1rem;
    background: #eee;
    border-radius: 0.3rem;
`;

export const logo = css`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const modalContainer = css`
    border-radius: 0.5rem;
    padding: 2rem;
    background: white;
`;

export const modalTitle = css`
    color: #388e3c;
    margin-top: 3rem;
    margin-bottom: 0.3rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.6rem;
`;

export const modalText = css`
    font-weight: 500;
    line-height: 1.4;

    &:first-of-type {
        text-align: center;
    }
`;

export const modalCode = css`
    display: inline-block;
    background: #eeeeee;
    font-weight: 600;
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    color: #2d2d2d;
`;
