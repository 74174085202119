import React, { useReducer, useMemo } from 'react';
import { Link } from 'gatsby';
import fetch from 'unfetch';
import { AnimatePresence, motion } from 'framer-motion';
import Cleave from 'cleave.js/react';

import { Wrapper, SEO, Cart, Icon, Checkbox, Button, Modal, Logo, PromoCodeForm } from '@components';
import { useCart } from '@hooks';
import { lazyLoadScript } from '@lib';

import * as s from './_styles';

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>;

const getAcceptJs = lazyLoadScript<any>(
    process.env.AUTHORIZE_ACCEPT_JS,
    () =>
        new Promise((resolve) => {
            // https://community.developer.authorize.net/t5/Integration-and-Testing/Dynamically-loading-Accept-js-E-WC-03-Accept-js-is-not-loaded/td-p/63283#
            const handleHandshake = () => {
                document.body.removeEventListener('handshake', handleHandshake);
                resolve(window.Accept);
            };
            document.body.addEventListener('handshake', handleHandshake);
        })
);

enum Status {
    DEFAULT,
    ERROR,
    FIELDS,
    OVER21,
    TERMS,
    WAITING,
    SUCCESS,
    MASTERCARD,
}

interface FormData {
    sameBilling: boolean;
    over21: boolean;
    terms: boolean;
    note: string;
    // Contact
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    // Shipping
    shippingAddressLine: string;
    shippingAddressLine2: string;
    shippingCity: string;
    shippingState: string;
    shippingZip: string;
    // Billing
    billingAddressLine: string;
    billingAddressLine2: string;
    billingCity: string;
    billingState: string;
    billingZip: string;
    // Card
    cardFullName: string;
    cardNumber: string;
    cardExpiration: string;
    cardCode: string;
    cardZip: string;
}

interface OrderDetails {
    id?: string;
    total?: string;
    email?: string;
}

interface State {
    status: Status;
    errorDescription: string;
    orderDetails: OrderDetails;
    cardType?: string;
    form: FormData;
}

type Action =
    | { type: 'DEFAULT_STATUS' | 'STATUS_WAITING' | 'CLEAR_ORDER_DETAILS' }
    | { type: 'STATUS_ERROR' | 'CARD_TYPE'; data: string }
    | { type: 'SET_STATUS'; data: Status }
    | { type: 'UPDATE_FORM'; data: Partial<FormData> }
    | { type: 'ORDER_SUCCESS'; data: OrderDetails };

const defaultForm: FormData = {
    sameBilling: false,
    over21: false,
    terms: false,
    note: '',
    // Contact
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    // Shipping
    shippingAddressLine: '',
    shippingAddressLine2: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: '',
    // Billing
    billingAddressLine: '',
    billingAddressLine2: '',
    billingCity: '',
    billingState: '',
    billingZip: '',
    // Card
    cardFullName: '',
    cardNumber: '',
    cardExpiration: '',
    cardCode: '',
    cardZip: '',
};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'DEFAULT_STATUS':
            return {
                ...state,
                status: Status.DEFAULT,
                errorDescription: '',
            };
        case 'SET_STATUS':
            return {
                ...state,
                status: action.data,
            };
        case 'STATUS_WAITING':
            return {
                ...state,
                status: Status.WAITING,
                errorDescription: '',
            };
        case 'STATUS_ERROR':
            return {
                ...state,
                status: Status.ERROR,
                errorDescription: action.data || '',
            };
        case 'UPDATE_FORM':
            return {
                ...state,
                form: {
                    ...state.form,
                    ...action.data,
                },
            };
        case 'ORDER_SUCCESS':
            return {
                ...state,
                status: Status.SUCCESS,
                orderDetails: action.data,
                form: {
                    ...defaultForm,
                },
            };
        case 'CLEAR_ORDER_DETAILS':
            return {
                ...state,
                orderDetails: {},
            };
        case 'CARD_TYPE':
            return {
                ...state,
                status: action.data === 'mastercard' ? Status.MASTERCARD : Status.DEFAULT,
                cardType: action.data,
            };
        default:
            return state;
    }
};

const CheckoutPage = () => {
    const { items, total, clear, isInState, setInState, promotions } = useCart();
    const [state, dispatch] = useReducer(reducer, {
        status: Status.DEFAULT,
        errorDescription: '',
        orderDetails: {},
        cardType: undefined,
        form: {
            ...defaultForm,
        },
    });
    const prefix = useMemo(() => Math.ceil(Math.random() * 10 ** 20), []);
    const formId = `${prefix}--form`;
    const buttonMessage = (() => {
        switch (state.status) {
            case Status.ERROR:
                return 'An error occurred. Please try again';
            case Status.FIELDS:
                return 'Please fill out all fields';
            case Status.OVER21:
                return 'Must be 21 or older to purchase';
            case Status.MASTERCARD:
                return 'Mastercard not currently supported online';
            case Status.TERMS:
                return 'Please read and accept our terms';
            case Status.WAITING:
                return '__LOADING__';
            case Status.SUCCESS:
                return 'Success! See email for details';
            case Status.DEFAULT:
            default:
                return `PURCHASE ($${(total / 100).toFixed(2)})`;
        }
    })();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue =
            event.target.type !== 'checkbox' ? event.target.value : (event.target as HTMLInputElement).checked;

        if ([Status.ERROR, Status.FIELDS, Status.OVER21, Status.TERMS].includes(state.status)) {
            dispatch({ type: 'DEFAULT_STATUS' });
        }

        if (event.target.name.includes('shippingState')) {
            const shippingState = event.target.value.trim().toLocaleLowerCase();
            const fuzzyMatchState =
                shippingState === '' ||
                'md'.includes(shippingState) ||
                (shippingState.length >= 3 && 'maryland'.includes(shippingState));

            if (isInState && !fuzzyMatchState) {
                setInState(false);
            } else if (!isInState && fuzzyMatchState) {
                setInState(true);
            }
        }

        dispatch({ type: 'UPDATE_FORM', data: { [event.target.name]: newValue } });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if ([Status.WAITING, Status.SUCCESS, Status.MASTERCARD].includes(state.status)) {
            return;
        }

        if (state.form.sameBilling) {
            state.form.billingAddressLine = state.form.shippingAddressLine;
            state.form.billingAddressLine2 = state.form.shippingAddressLine2;
            state.form.billingCity = state.form.shippingCity;
            state.form.billingState = state.form.shippingState;
            state.form.billingZip = state.form.shippingZip;
        }

        if (
            Object.entries(state.form)
                .filter(([k]) => k !== 'note' && !k.endsWith('AddressLine2'))
                .some(([, v]) => v === '')
        ) {
            dispatch({ type: 'SET_STATUS', data: Status.FIELDS });
            return;
        }

        if (!state.form.over21) {
            dispatch({ type: 'SET_STATUS', data: Status.OVER21 });
            return;
        }

        if (!state.form.terms) {
            dispatch({ type: 'SET_STATUS', data: Status.TERMS });
            return;
        }

        dispatch({ type: 'STATUS_WAITING' });

        const { opaqueData, messages } = await new Promise((resolve) => {
            getAcceptJs().then((Accept) => {
                const [month, year] = state.form.cardExpiration.split('/');

                Accept.dispatchData(
                    {
                        authData: {
                            clientKey: process.env.AUTHORIZE_PUBLIC_CLIENT_KEY,
                            apiLoginID: process.env.AUTHORIZE_API_LOGIN_ID,
                        },
                        cardData: {
                            fullName: state.form.cardFullName,
                            cardNumber: state.form.cardNumber.replace(/\s/g, ''),
                            month,
                            year,
                            cardCode: state.form.cardCode,
                            zip: state.form.cardZip,
                        },
                    },
                    resolve
                );
            });
        });

        if (messages.resultCode === 'Error') {
            dispatch({ type: 'STATUS_ERROR', data: messages.message[0].text });
            return;
        }

        const { note, ...info } = state.form as Optional<
            FormData,
            'cardFullName' | 'cardNumber' | 'cardExpiration' | 'cardCode' | 'cardZip'
        >;

        // Ensure sensitive data does not get sent up to our server
        delete info.cardFullName;
        delete info.cardNumber;
        delete info.cardExpiration;
        delete info.cardCode;
        delete info.cardZip;

        // console.log(1, opaqueData.dataValue);
        // return dispatch({ type: 'SET_STATUS', data: Status.DEFAULT });

        try {
            const res = await fetch(process.env.API_CREATE_ORDER, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    items: items.map(({ code, quantity }) => ({ code, quantity })),
                    amount: total,
                    opaqueData,
                    contact: {
                        firstName: info.firstName,
                        lastName: info.lastName,
                        phone: info.phone,
                        email: info.email,
                    },
                    shipping: {
                        addressLine: info.shippingAddressLine,
                        addressLine2: info.shippingAddressLine2,
                        city: info.shippingCity,
                        state: info.shippingState,
                        zip: info.shippingZip,
                    },
                    billing: {
                        addressLine: info.billingAddressLine,
                        addressLine2: info.billingAddressLine2,
                        city: info.billingCity,
                        state: info.billingState,
                        zip: info.billingZip,
                    },
                    note,
                    codes: promotions.map(({ code }) => code),
                }),
            });

            if (res.status !== 200) {
                dispatch({ type: 'STATUS_ERROR', data: await res.text() });
            } else {
                dispatch({
                    type: 'ORDER_SUCCESS',
                    data: {
                        id: await res.text(),
                        email: info.email,
                        total: `$${(total / 100).toFixed(2)}`,
                    },
                });
                clear();
            }
        } catch (error) {
            dispatch({ type: 'STATUS_ERROR', data: error.message });
        }
    };

    return (
        <Wrapper>
            <SEO title="Cart" keywords={[`gatsby`, `application`, `react`]} />
            {/* Standalone form with input form attributes to link due to the nested promo code form */}
            <form id={formId} onSubmit={handleSubmit} />
            <h2 css={s.title}>Checkout</h2>
            <div css={s.fields}>
                <div>
                    <h3 css={s.sectionTitle}>Your Order</h3>
                    <Cart />
                    <h3 css={s.sectionTitle}>Promotion Code</h3>
                    <PromoCodeForm />
                    <h3 css={s.sectionTitle}>Add a Note</h3>
                    <label css={s.label} htmlFor={`${prefix}--note`}>
                        <textarea
                            id={`${prefix}--note`}
                            name="note"
                            form={formId}
                            placeholder="Special instructions or requests"
                            onChange={handleInputChange}
                            value={state.form.note}
                        />
                    </label>
                </div>
                <div>
                    <h3 css={s.sectionTitle}>Contact Details</h3>
                    <div css={s.labelGroup}>
                        <label css={s.label} htmlFor={`${prefix}--firstName`}>
                            <input
                                type="text"
                                id={`${prefix}--firstName`}
                                name="firstName"
                                form={formId}
                                placeholder="First Name"
                                onChange={handleInputChange}
                                value={state.form.firstName}
                            />
                        </label>
                        <label css={s.label} htmlFor={`${prefix}--lastName`}>
                            <input
                                type="text"
                                id={`${prefix}--lastName`}
                                name="lastName"
                                form={formId}
                                placeholder="Last Name"
                                onChange={handleInputChange}
                                value={state.form.lastName}
                            />
                        </label>
                    </div>
                    <label css={s.label} htmlFor={`${prefix}--phone`}>
                        <input
                            type="text"
                            id={`${prefix}--phone`}
                            name="phone"
                            form={formId}
                            placeholder="Phone Number"
                            onChange={handleInputChange}
                            value={state.form.phone}
                        />
                    </label>
                    <label css={s.label} htmlFor={`${prefix}--email`}>
                        <input
                            type="text"
                            id={`${prefix}--email`}
                            name="email"
                            form={formId}
                            placeholder="Email"
                            onChange={handleInputChange}
                            value={state.form.email}
                        />
                    </label>

                    <h3 css={s.sectionTitle}>Shipping Details</h3>
                    <label css={s.label} htmlFor={`${prefix}--shippingAddressLine`}>
                        <input
                            type="text"
                            id={`${prefix}--shippingAddressLine`}
                            name="shippingAddressLine"
                            form={formId}
                            placeholder="Address Line"
                            onChange={handleInputChange}
                            value={state.form.shippingAddressLine}
                        />
                    </label>
                    <label css={s.label} htmlFor={`${prefix}--shippingAddressLine2`}>
                        <input
                            type="text"
                            id={`${prefix}--shippingAddressLine2`}
                            name="shippingAddressLine2"
                            form={formId}
                            placeholder="Address Line 2"
                            onChange={handleInputChange}
                            value={state.form.shippingAddressLine2}
                        />
                    </label>
                    <label css={s.label} htmlFor={`${prefix}--shippingCity`}>
                        <input
                            type="text"
                            id={`${prefix}--shippingCity`}
                            name="shippingCity"
                            form={formId}
                            placeholder="City"
                            onChange={handleInputChange}
                            value={state.form.shippingCity}
                        />
                    </label>
                    <div css={s.labelGroup}>
                        <label css={s.label} htmlFor={`${prefix}--shippingState`}>
                            <input
                                type="text"
                                id={`${prefix}--shippingState`}
                                name="shippingState"
                                form={formId}
                                placeholder="State"
                                onChange={handleInputChange}
                                value={state.form.shippingState}
                            />
                        </label>
                        <label css={s.label} htmlFor={`${prefix}--shippingZip`}>
                            <input
                                type="text"
                                id={`${prefix}--shippingZip`}
                                name="shippingZip"
                                form={formId}
                                placeholder="Zip/Postal Code"
                                onChange={handleInputChange}
                                value={state.form.shippingZip}
                            />
                        </label>
                    </div>

                    <h3 css={s.sectionTitle}>Billing Details</h3>
                    <label css={s.checkboxLabel} htmlFor={`${prefix}--sameBilling`}>
                        <Checkbox
                            id={`${prefix}--sameBilling`}
                            name="sameBilling"
                            form={formId}
                            onChange={handleInputChange}
                            checked={state.form.sameBilling}
                        />
                        <span>Same as shipping address</span>
                    </label>
                    <AnimatePresence initial={false}>
                        {!state.form.sameBilling && (
                            <motion.div
                                key="billingDetails"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 30 }}
                            >
                                <label css={s.label} htmlFor={`${prefix}--billingAddressLine`}>
                                    <input
                                        type="text"
                                        id={`${prefix}--billingAddressLine`}
                                        name="billingAddressLine"
                                        form={formId}
                                        placeholder="Address Line"
                                        onChange={handleInputChange}
                                        value={state.form.billingAddressLine}
                                    />
                                </label>
                                <label css={s.label} htmlFor={`${prefix}--billingAddressLine2`}>
                                    <input
                                        type="text"
                                        id={`${prefix}--billingAddressLine2`}
                                        name="billingAddressLine2"
                                        form={formId}
                                        placeholder="Address Line 2"
                                        onChange={handleInputChange}
                                        value={state.form.billingAddressLine2}
                                    />
                                </label>
                                <label css={s.label} htmlFor={`${prefix}--billingCity`}>
                                    <input
                                        type="text"
                                        id={`${prefix}--billingCity`}
                                        name="billingCity"
                                        form={formId}
                                        placeholder="City"
                                        onChange={handleInputChange}
                                        value={state.form.billingCity}
                                    />
                                </label>
                                <div css={s.labelGroup}>
                                    <label css={s.label} htmlFor={`${prefix}--billingState`}>
                                        <input
                                            type="text"
                                            id={`${prefix}--billingState`}
                                            name="billingState"
                                            form={formId}
                                            placeholder="State"
                                            onChange={handleInputChange}
                                            value={state.form.billingState}
                                        />
                                    </label>
                                    <label css={s.label} htmlFor={`${prefix}--billingZip`}>
                                        <input
                                            type="text"
                                            id={`${prefix}--billingZip`}
                                            name="billingZip"
                                            form={formId}
                                            placeholder="Zip/Postal Code"
                                            onChange={handleInputChange}
                                            value={state.form.billingZip}
                                        />
                                    </label>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    <h3 css={s.sectionTitle}>Payment Details</h3>
                    <label css={s.label} htmlFor={`${prefix}--cardFullName`}>
                        <input
                            type="text"
                            id={`${prefix}--cardFullName`}
                            name="cardFullName"
                            form={formId}
                            placeholder="Full Name on Card"
                            onChange={handleInputChange}
                            value={state.form.cardFullName}
                        />
                    </label>
                    <label css={s.label} htmlFor={`${prefix}--cardNumber`}>
                        <Cleave
                            type="text"
                            id={`${prefix}--cardNumber`}
                            name="cardNumber"
                            form={formId}
                            placeholder="Card Number"
                            onChange={handleInputChange}
                            value={state.form.cardNumber}
                            options={{
                                creditCard: true,
                                onCreditCardTypeChanged: (cardType) => dispatch({ type: 'CARD_TYPE', data: cardType }),
                            }}
                        />
                        <div css={s.cardIcon}>
                            <Icon name={`cc-${state.cardType}`} />
                        </div>
                    </label>
                    <div css={s.labelGroup}>
                        <label css={s.label} htmlFor={`${prefix}--cardExpiration`}>
                            <Cleave
                                type="text"
                                id={`${prefix}--cardExpiration`}
                                name="cardExpiration"
                                form={formId}
                                placeholder="MM/YY"
                                onChange={handleInputChange}
                                value={state.form.cardExpiration}
                                options={{ date: true, datePattern: ['m', 'y'] }}
                            />
                        </label>
                        <label css={s.label} htmlFor={`${prefix}--cardCode`}>
                            <Cleave
                                type="text"
                                id={`${prefix}--cardCode`}
                                name="cardCode"
                                form={formId}
                                placeholder="CVV"
                                onChange={handleInputChange}
                                value={state.form.cardCode}
                                options={{ numericOnly: true, blocks: [4] }}
                            />
                        </label>
                        <label css={s.label} htmlFor={`${prefix}--cardZip`}>
                            <Cleave
                                type="text"
                                id={`${prefix}--cardZip`}
                                name="cardZip"
                                form={formId}
                                placeholder="Zip"
                                onChange={handleInputChange}
                                value={state.form.cardZip}
                                options={{ numericOnly: true, blocks: [5] }}
                            />
                        </label>
                    </div>
                    <div css={s.requiredChecks}>
                        <label css={s.checkboxLabel} htmlFor={`${prefix}--over21`}>
                            <Checkbox
                                id={`${prefix}--over21`}
                                name="over21"
                                form={formId}
                                onChange={handleInputChange}
                                checked={state.form.over21}
                            />
                            <span>I confirm that I am at least 21 years old or older</span>
                        </label>
                        <label css={s.checkboxLabel} htmlFor={`${prefix}--terms`}>
                            <Checkbox
                                id={`${prefix}--terms`}
                                name="terms"
                                form={formId}
                                onChange={handleInputChange}
                                checked={state.form.terms}
                            />
                            <span>
                                I confirm that I have read and accept the{' '}
                                <Link to="/policy/terms">Terms &amp; Conditions</Link> and{' '}
                                <Link to="/policy/return">Return &amp; Refund Policy</Link>
                            </span>
                        </label>
                    </div>
                    <Button
                        type="submit"
                        form={formId}
                        color={
                            [Status.DEFAULT, Status.WAITING, Status.SUCCESS].includes(state.status)
                                ? '#388e3c'
                                : '#ad2c2c'
                        }
                    >
                        {buttonMessage}
                    </Button>
                    {state.errorDescription && <div css={s.errorMessage}>{state.errorDescription}</div>}
                </div>
            </div>
            <Modal open={!!state.orderDetails.id} onClose={() => dispatch({ type: 'CLEAR_ORDER_DETAILS' })}>
                <div css={s.modalContainer}>
                    <div css={s.logo}>
                        <Logo />
                    </div>
                    <h2 css={s.modalTitle}>Successful Payment</h2>
                    <p css={s.modalText}>
                        Order Confirmation ID: <code css={s.modalCode}>{state.orderDetails.id}</code>
                    </p>
                    <br />
                    <p css={s.modalText}>
                        Thank you for your purchase of {state.orderDetails.total}. We sent a copy of your receipt to{' '}
                        {state.orderDetails.email}.
                    </p>
                </div>
            </Modal>
        </Wrapper>
    );
};

export default CheckoutPage;
